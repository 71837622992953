<template scoped>
  <div>
    <el-form-item
      :rules="rules.interface"
      label="Interface"
      prop="connect.bluetooth.interface"
      :style="{ marginTop: '10px' }"
    >
      <el-select
        @change="interface_change"
        v-model="logic.connect.bluetooth.interface"
        :style="{ width: '100%' }"
        placeholder="Select the Interface"
      >
        <el-option
          v-for="item in supportInterface"
          :key="item.value"
          :label="item.name"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </el-form-item>

    <component :is="content" :logic="logic"></component>
  </div>
</template>

<script>
export default {
  props: {
    logic: Object,
    data: Object,
  },
  computed: {},
  data: function () {
    return {
      content: null,
      supportInterface: [
        {
          name: "Bluetooth Classic",
          value: "BluetoothClassic",
        },
        {
          name: "Bluetooth Low Energy",
          value: "BluetoothLowEnergy",
        },
      ],
      rules: {
        interface: [{ validator: this.validator_interface, trigger: "blur" }],
      },
    };
  },
  created:function(){
    if (this.logic.connect.bluetooth.interface){
      this.interface_change(this.logic.connect.bluetooth.interface);
    }
  },
  methods: {
    validator_interface: function (rule, value, callback) {
      if (!value) {
        callback(new Error("the interface should not be empty"));
      } else {
        callback();
      }
    },
    interface_change: function (val) {
      switch (val) {
        case "BluetoothLowEnergy":
          this.content = () =>
            import(
              "./LowEnengy/Panel"
            );
          break;
        case "BluetoothClassic":
          this.content = () =>
            import(
              "./Classic/Panel"
            );
          break;
        default:
          this.content = null;
          break;
      }
    },
  },
};
</script>